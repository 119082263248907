import React, { useState } from 'react'
import { Segment, Grid, Label, Sidebar, Divider, Header, Table, Popup, Icon } from 'semantic-ui-react'
import { formatNumber, isEmpty, roundToNearest } from '../utility/Helper';
import CompsCalcPanel, { CompsExtraCalcPanel } from './CompsCalcPanel';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ContainedAccordian } from './extras/ContainedAccordian';
import SaleChart from './charts/SaleChart';
import RadiusChart from './charts/RadiusChart';
import LTDSChart from './charts/LTDSChart';
import Lot from '../Tax-Lot';
import { LotMiscInformationPanel, ZillowEstimatesPanel } from './lots/LotInformationPanel';
import Spinner from './Loader';

const colWidthOne = 7
const colWidthTwo = 9

function getNeighborhoodSaleTotals(totals, unit = null) {
    if (totals === null || totals === undefined || totals.length === 0) {
        return 0
    }

    if (unit) {
        totals = totals.filter(_ => _.unitCode === unit)
    }

    if (totals.length === 0) {
        return 0
    }

    const total = totals.map(_ => _.totalSales).reduce((a, b) => a + b)

    return total
}

function getNeighborhoodPSFTotals(totals, unit = null) {
    if (totals === null || totals === undefined || totals.length === 0) {
        return 0
    }

    if (unit) {
        totals = totals.filter(_ => _.unitCode === unit)
    }

    if (totals.length === 0) {
        return 0
    }

    const total = totals.map(_ => _.averagePSF).reduce((a, b) => a + b)

    return formatNumber(total / totals.length)
}

function getTotalStreetTurnoverSales(totals, streetId, unit = null) {
    if (totals === null || totals === undefined || totals.length === 0) {
        return 0
    }
    let t = totals.filter(_ => _.streetId === streetId)

    if (unit) {
        t = t.filter(_ => _.unitCode === unit)
    }

    if (t.length === 0) {
        return 0
    }

    const total = t.map(_ => _.totalSales).reduce((a, b) => a + b)

    return total
}

function getAverageNeighborhoodStreetTotal(neighborhoodTotal, unit = null) {
    if (neighborhoodTotal === null || neighborhoodTotal === undefined) {
        return 0
    }

    const totalCount = getTotalLotsByUnits(neighborhoodTotal, unit)

    const { totalStreets } = neighborhoodTotal

    return formatNumber(totalCount / totalStreets)
}

export function getTotalLotsByUnits(total, unit = null) {
    if (total === null || total === undefined) {
        return 0
    }

    const { unitCounts } = total

    let totalCount = 0

    if (unit) {
        totalCount = unitCounts[unit]
    } else {
        for (var i = 1; i <= 4; i++) {
            totalCount += unitCounts[i]
        }
    }

    return totalCount
}

function getTotalBldgAreaAverage(totals, unit = null) {
    if (totals === null || totals === undefined || totals.length === 0) {
        return 0
    }

    if (unit) {
        totals = totals.filter(_ => _.unitCode === unit)
    }

    if (totals.length === 0) return 0

    const total = totals.map(_ => _.averageBldgArea).reduce((a, b) => a + b)

    return total / totals.length
}

function getActualUnitCode(unitCode) {
    switch (unitCode) {
        case 11:
            return 1
        case 21:
            return 2
        case 31:
            return 3
        case 41:
            return 4
        default:
            return unitCode
    }
}

const StreetInfoPanel = ({ street, ranking, phaseTwoSaleRankings, phaseTwoLoanRankings }) => (
    <Table.Row>
        <Table.Cell textAlign='left'>
            {street.streetName}
        </Table.Cell>
        {
            ranking ? (
                <>
                    <Table.Cell><Label className='no-border' basic color={`${ranking.finalPercentile <= 10 ? 'green' : ranking.finalPercentile >= 80 ? 'red' : ''}`}>{ranking.finalPercentile}%</Label></Table.Cell>
                    <Table.Cell>{ranking.rank}/{ranking.totalStreets}</Table.Cell>
                </>
            ) : <Table.Cell colSpan='4'>Not Enough Data</Table.Cell>
        }
    </Table.Row>
)


const RentInfoPanel = ({ unit, bedroom, rent }) => (
    <Table.Row>
        <Table.Cell>
            {unit}
        </Table.Cell>
        <Table.Cell>
            {bedroom}
        </Table.Cell>
        <Table.Cell>
            ${formatNumber(rent)}
        </Table.Cell>
    </Table.Row>
)

export const CombinedCompsSidebar = ({ animation, direction, width, visible, handleAnimationChange, compsData, compsLoading, selectedLot }) => {
    const {buckets, cityBuckets, comps, cityComps, tdsChart, cityTdsChart, data, cityData, combinedBuckets, combinedTdsChart} = compsData || {}

    return (
        <Sidebar
            className='comps-side-panel-container combined-comps-side-panel-container'
            as={Segment}
            animation={animation}
            direction={direction}
            visible={visible}
            width={width}
        >
            <Grid style={{ height: '100%' }}>
                <Label className='panel-closer left comps-panel-closer' basic icon={`long arrow alternate ${visible ? 'right' : 'left'}`} floating onClick={handleAnimationChange}></Label>
                <Grid.Row columns={1} style={{ height: '100%' }}>
                    <Grid.Column style={{ height: '100%' }}>
                        <PerfectScrollbar style={{ height: '100%' }} options={{ suppressScrollX: true }}>
                            <div>
                                <section>
                                {!isEmpty(tdsChart) ? (
                                                    <>
                                                        <Popup
                                                            className='popup-tds-chart'
                                                            wide='very'
                                                            position='left center'
                                                            on='click'
                                                            pinned
                                                            content={
                                                                <Grid columns={!isEmpty(cityTdsChart) ? 3 : 2}>
                                                                    <Grid.Row>
                                                                        {tdsChart?.data.length > 0 && (
                                                                            <Grid.Column>
                                                                                <LTDSChart
                                                                                    chartData={tdsChart}
                                                                                    colorKey={'finalColor'}
                                                                                />
                                                                            </Grid.Column>
                                                                        )}
                                                                        {cityTdsChart?.data.length > 0 && (
                                                                            <Grid.Column>
                                                                                <LTDSChart
                                                                                    chartData={cityTdsChart}
                                                                                    colorKey={'finalColor'}
                                                                                />
                                                                            </Grid.Column>
                                                                        )}
                                                                        {combinedTdsChart?.data.length > 0 && (
                                                                            <Grid.Column>
                                                                                <LTDSChart
                                                                                    chartData={combinedTdsChart}
                                                                                    colorKey={'combinedColor'}
                                                                                    showOutliers={true}
                                                                                />
                                                                            </Grid.Column>
                                                                        )}
                                                                    </Grid.Row>
                                                                </Grid>
                                                            } trigger={<Icon style={{ cursor: 'pointer' }} name='caret down' />} />
                                                    </>
                                                ) : null}
                                    {
                                        buckets ? (
                                            <>
                                            <div>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h3 style={{textAlign: 'center'}}>Combined</h3> 
                                                    <CompsCalcPanel
                                                        selectedLot={selectedLot}
                                                        //calc={compsCalc}
                                                        compsBuckets={combinedBuckets}
                                                        //compsData={data}
                                                        //bestBucketSet={bestBucketSet}
                                                        loading={compsLoading}
                                                        displayAreaChart={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className='combined-comps-container'>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h3 style={{textAlign: 'center'}}>Standard</h3> 
                                                    <CompsCalcPanel
                                                        selectedLot={selectedLot}
                                                        //calc={compsCalc}
                                                        compsBuckets={buckets}
                                                        compsData={data}
                                                        //bestBucketSet={bestBucketSet}
                                                        loading={compsLoading}
                                                    />
                                                </div>
                                                <div style={{margin: '2em', border: '1px solid rgba(34,36,38,.15)'}}></div>
                                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h3 style={{textAlign: 'center'}}>City</h3>
                                                    <CompsCalcPanel
                                                        selectedLot={selectedLot}
                                                        //calc={compsCalc}
                                                        compsBuckets={cityBuckets}
                                                        compsData={cityData}
                                                        //bestBucketSet={bestBucketSet}
                                                        loading={compsLoading}
                                                    />
                                                </div>
                                            </div>

                                            </>
                                        ) : null
                                    }
                                </section>
                            </div>
                            <div style={{textAlign: 'center'}}>
                            <Spinner active={compsLoading} />
                            </div>
                        </PerfectScrollbar>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Sidebar>
    )
}


export const CompsSidebar = ({ animation, direction, width, visible, handleAnimationChange, compsBuckets, compsStats, neighborhoodAverages, compsLoading, selectedLot, salesChart, radiusChart, tdsChart, compType, subType }) => {
    const {valuationData, chartData, rangeData, sameSizeTally} = compsStats || {}
    
    const { saleTrends, unitSaleTrends, movingAverage, unitMovingAverage, weightedAverages } = salesChart || {}
    const hasStreetData = selectedLot.streets && selectedLot.streets.length > 0

    const actualUnitCode = getActualUnitCode(selectedLot.unitCode)
    const n_total = roundToNearest(getAverageNeighborhoodStreetTotal(selectedLot.neighborhoodTotals))
    const n_unit_total = roundToNearest(getAverageNeighborhoodStreetTotal(selectedLot.neighborhoodTotals, actualUnitCode))

    const n_s_total = getNeighborhoodSaleTotals(selectedLot.neighborhoodSaleTotals)
    const n_s_unit_total = getNeighborhoodSaleTotals(selectedLot.neighborhoodSaleTotals, actualUnitCode)

    const n_s_three_years_total = getNeighborhoodSaleTotals(selectedLot.neighborhoodSaleTotalsThreeYears)
    const n_s_three_years_unit_total = getNeighborhoodSaleTotals(selectedLot.neighborhoodSaleTotalsThreeYears, actualUnitCode)

    const s_s_total = selectedLot.streets && selectedLot.streets.length > 0 ? getTotalStreetTurnoverSales(selectedLot.streetSaleTotals, selectedLot.streets[0].streetId) : 0
    const s_s_unit_total = selectedLot.streets && selectedLot.streets.length > 0 ? getTotalStreetTurnoverSales(selectedLot.streetSaleTotals, selectedLot.streets[0].streetId, actualUnitCode) : 0

    const { totalLots, totalStreets, totalResUnits } = selectedLot.neighborhoodTotals ?? 0
    const totalNeighborhoodResLots = getTotalLotsByUnits(selectedLot.neighborhoodTotals)
    const totalNieghboorhoodUnitLots = getTotalLotsByUnits(selectedLot.neighborhoodTotals, actualUnitCode)

    let street_total_res_lots = 0
    let street_total_lots = 0
    let street_unit_lots = 0

    if (selectedLot.streetTotals && selectedLot.streetTotals.length > 0) {
        street_total_lots = selectedLot.streetTotals[0].totalLots
        street_total_res_lots = getTotalLotsByUnits(selectedLot.streetTotals[0])
        street_unit_lots = getTotalLotsByUnits(selectedLot.streetTotals[0], actualUnitCode)
    }

    const neighborhoodAverageResUnits = roundToNearest(totalStreets > 0 ? totalResUnits / totalStreets : 0)
    const neighborhoodDensity = totalLots > 0 ? totalResUnits / totalLots : 0

    const streetTotalTurnover = street_total_lots > 0 ? (s_s_total / street_total_res_lots) * 100 : 0
    const streetUnitTurnover = street_total_lots > 0 ? (s_s_unit_total / street_total_res_lots) * 100 : 0

    const neighborhoodTotalTurnover = totalLots > 0 ? (n_s_three_years_total / totalLots) * 100 : 0
    const neighborhoodUnitTurnover = totalLots > 0 ? (n_s_three_years_unit_total / totalLots) * 100 : 0

    const bedrooms = selectedLot?.bedrooms
    const rents = selectedLot?.rents

    const totalBedrooms = bedrooms != null && bedrooms.length > 0 ? bedrooms.reduce((a, b) => a + b) : 0
    const totalRent = rents != null && rents.length > 0 ? rents.reduce((a, b) => a + b) : 0

    const {notEnoughComps} = valuationData || {}
    return (
        <Sidebar
            className='comps-side-panel-container'
            as={Segment}
            animation={animation}
            direction={direction}
            visible={visible}
            width={width}
        >
            <Grid style={{ height: '100%' }}>
                <Label className='panel-closer left comps-panel-closer' basic icon={`long arrow alternate ${visible ? 'right' : 'left'}`} floating onClick={handleAnimationChange}></Label>
                <Grid.Row columns={1} style={{ height: '100%' }}>
                    <Grid.Column style={{ height: '100%' }}>
                        <PerfectScrollbar style={{ height: '100%' }} options={{ suppressScrollX: true }}>
                            <div>
                                <section>
                                    {
                                        compsBuckets ? (
                                            <>
                                                <Segment className='no-padding' basic textAlign='center'>
                                                    {!isEmpty(salesChart) ? (
                                                        <Popup
                                                            className='popup-sale-chart'
                                                            wide='very'
                                                            position='left center'
                                                            on='click'
                                                            pinned
                                                            content={
                                                                <Segment basic className='popup-sale-chart-container no-padding'>
                                                                    <SaleChart
                                                                        chartData={saleTrends}
                                                                        movingAverage={movingAverage}
                                                                        unitMovingAverage={unitMovingAverage}
                                                                        weightedAverages={weightedAverages}
                                                                        scatterSize={1}
                                                                        showLegend={false}
                                                                        brushArea={Lot.bldgarea(selectedLot)}
                                                                        sameColor={true} />
                                                                </Segment>
                                                            } trigger={<Icon style={{ cursor: 'pointer' }} name='caret left' />} />
                                                    ) : null}
                                                    {notEnoughComps ? <span><b>Insufficient Comparable Sales</b></span> : ''}
                                                    {!isEmpty(salesChart) ? (
                                                        <Popup
                                                            className='popup-sale-chart'
                                                            wide='very'
                                                            position='left center'
                                                            on='click'
                                                            pinned
                                                            content={
                                                                <Segment basic className='popup-sale-chart-container no-padding'>
                                                                    <SaleChart
                                                                        chartData={unitSaleTrends}
                                                                        movingAverage={null}
                                                                        unitMovingAverage={unitMovingAverage}
                                                                        weightedAverages={weightedAverages}
                                                                        scatterSize={1}
                                                                        showLegend={false}
                                                                        brushArea={Lot.bldgarea(selectedLot)}
                                                                        sameColor={true} />
                                                                </Segment>
                                                            } trigger={<Icon style={{ cursor: 'pointer' }} name='caret right' />} />
                                                    ) : null}
                                                </Segment>
                                                {!isEmpty(tdsChart) ? (
                                                    <>
                                                        <Popup
                                                            className='popup-tds-chart'
                                                            wide='very'
                                                            position='left center'
                                                            on='click'
                                                            pinned
                                                            content={
                                                                <Grid columns={!isEmpty(salesChart) ? 2 : 1}>
                                                                    <Grid.Row>
                                                                        {tdsChart?.data.length > 0 && (
                                                                            <Grid.Column>
                                                                                <LTDSChart
                                                                                    chartData={tdsChart}
                                                                                    colorKey={'finalColor'}
                                                                                />
                                                                            </Grid.Column>
                                                                        )}
                                                                        {!isEmpty(salesChart) && (
                                                                            <Grid.Column>
                                                                                <SaleChart
                                                                                    chartData={unitSaleTrends}
                                                                                    movingAverage={null}
                                                                                    unitMovingAverage={unitMovingAverage}
                                                                                    weightedAverages={weightedAverages}
                                                                                    scatterSize={1}
                                                                                    showLegend={false}
                                                                                    brushArea={Lot.bldgarea(selectedLot)}
                                                                                    sameColor={true} />
                                                                            </Grid.Column>
                                                                        )}
                                                                    </Grid.Row>
                                                                </Grid>
                                                            } trigger={<Icon style={{ cursor: 'pointer' }} name='caret down' />} />
                                                        <Popup
                                                            className='popup-tds-chart'
                                                            wide='very'
                                                            position='left center'
                                                            on='click'
                                                            pinned
                                                            content={
                                                                <Grid columns={!isEmpty(salesChart) ? 2 : 1}>
                                                                    <Grid.Row>
                                                                        {tdsChart?.data.length > 0 && (
                                                                            <Grid.Column>
                                                                                <LTDSChart
                                                                                    chartData={tdsChart}
                                                                                    showOutliers={true}
                                                                                    colorKey={'finalColor'}
                                                                                />
                                                                            </Grid.Column>
                                                                        )}
                                                                        {!isEmpty(salesChart) && (
                                                                            <Grid.Column>
                                                                                <SaleChart
                                                                                    chartData={unitSaleTrends}
                                                                                    movingAverage={null}
                                                                                    unitMovingAverage={unitMovingAverage}
                                                                                    weightedAverages={weightedAverages}
                                                                                    scatterSize={1}
                                                                                    showLegend={false}
                                                                                    brushArea={Lot.bldgarea(selectedLot)}
                                                                                    showOutliers={true}
                                                                                    sameColor={true} />
                                                                            </Grid.Column>
                                                                        )}
                                                                    </Grid.Row>
                                                                </Grid>
                                                            } trigger={<Icon style={{ cursor: 'pointer', float: 'right' }} name='caret down' />} />
                                                    </>
                                                ) : null}
                                                <CompsCalcPanel
                                                    selectedLot={selectedLot}
                                                    neighborhoodAverages={neighborhoodAverages}
                                                    //calc={compsCalc}
                                                    compsBuckets={compsBuckets}
                                                    compsData={valuationData}
                                                    rangeData={rangeData}
                                                    chartData={chartData}
                                                    sameSizeTally={sameSizeTally}
                                                    //bestBucketSet={bestBucketSet}
                                                    loading={compsLoading}
                                                    subType={subType}
                                                />
                                            </>
                                        ) : null
                                    }
                                    {
                                        selectedLot ? (
                                            <>
                                                <Grid className="lot-details" textAlign='left' columns={2}>

                                                    {selectedLot.zestimate && (
                                                        <ZillowEstimatesPanel lot={selectedLot} />
                                                    )}

                                                </Grid>
                                                <Divider />
                                                {/* {Lot.isAttom(selectedLot) && (
                                                    <LotMiscInformationPanel lot={selectedLot} />
                                                )} */}
                                            </>
                                        ) : null
                                    }
                                </section>
                            </div>
                        </PerfectScrollbar>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Sidebar>
    )
}


/*
{Lot.isPluto(selectedLot) && bedrooms ? (
    <ContainedAccordian active={false} titlePosition='center' title='Projected Rent'>
        <Table celled compact='very' size='small' textAlign='center'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Unit</Table.HeaderCell>
                    <Table.HeaderCell>Bedrooms</Table.HeaderCell>
                    <Table.HeaderCell>Rent</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {bedrooms.map((item, index) => {
                    return <RentInfoPanel
                        key={index}
                        unit={index + 1}
                        bedroom={item}
                        rent={rents.length > index ? rents[index] : 0}
                    />
                })}
                <Table.Row>
                    <Table.Cell>
                        Total
                    </Table.Cell>
                    <Table.Cell>
                        {totalBedrooms}
                    </Table.Cell>
                    <Table.Cell>
                        ${formatNumber(totalRent)}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </ContainedAccordian>
) : null}

{hasStreetData && (
    <>
        {/* <Table celled compact='very' size='small' textAlign='center'>
<Table.Header>
<Table.Row>
<Table.HeaderCell colSpan='3'>Street Rankings</Table.HeaderCell>
</Table.Row>
<Table.Row>
<Table.HeaderCell>Street</Table.HeaderCell>
<Table.HeaderCell>Quality</Table.HeaderCell>
<Table.HeaderCell>Street Rank</Table.HeaderCell>
</Table.Row>
</Table.Header>
<Table.Body>
{selectedLot.streets.map((item, index) => {
return <StreetInfoPanel
    key={index}
    street={item}
    ranking={selectedLot.rankings?.find(_ => _.streetId === item.streetId)}
    neighborhoodTotal={selectedLot.neighborhoodTotals}
    />
})}    
</Table.Body>
</Table> 

        <Table celled compact='very' size='small' textAlign='center'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{selectedLot.neighborhood} Data</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell>12 Mo Sales</Table.HeaderCell>
                    <Table.HeaderCell>Avg PSF</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {/* <Table.Row>
    <Table.Cell textAlign='left'>
        # of Residential Properties
    </Table.Cell>
    <Table.Cell>{selectedLot.neighborhoodTotals.totalAllResLots}</Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
</Table.Row> 
                <Table.Row>
                    <Table.Cell textAlign='left'>
                        # of 1-4 Unit props
                    </Table.Cell>
                    <Table.Cell>{totalNeighborhoodResLots}</Table.Cell>
                    <Table.Cell>{formatNumber(n_s_total)}</Table.Cell>
                    <Table.Cell>${getNeighborhoodPSFTotals(selectedLot.neighborhoodSaleTotals)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell textAlign='left'>
                        # of {actualUnitCode} Unit Props
                    </Table.Cell>
                    <Table.Cell>{totalNieghboorhoodUnitLots}</Table.Cell>
                    <Table.Cell>{formatNumber(n_s_unit_total)}</Table.Cell>
                    <Table.Cell>${getNeighborhoodPSFTotals(selectedLot.neighborhoodSaleTotals, actualUnitCode)}</Table.Cell>
                </Table.Row>
            </Table.Body>



            {selectedLot?.streetTotals && selectedLot.streetTotals.length > 0 ? (
                <>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'></Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Street Composition</Table.HeaderCell>
                            <Table.HeaderCell>Total</Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>Percentage</Table.HeaderCell>
                            {/* <Table.HeaderCell>{selectedLot.neighborhood} Avg</Table.HeaderCell> 
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {/* <Table.Row>
    <Table.Cell textAlign='left'>
        # of Residential Properties
    </Table.Cell>
    <Table.Cell>{selectedLot?.streetTotals[0]?.totalAllRes}</Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
</Table.Row> 
                        <Table.Row>
                            <Table.Cell textAlign='left'>
                                # of 1-4 Unit Props
                            </Table.Cell>
                            <Table.Cell>{street_total_res_lots}</Table.Cell>
                            <Table.Cell colSpan='2'>{formatNumber((street_total_res_lots / street_total_lots) * 100)}%</Table.Cell>
                            {/* <Table.Cell>{formatNumber((n_total/street_total_res_lots) * 100)}%</Table.Cell> 
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell textAlign='left'>
                                # of {actualUnitCode} Unit Props
                            </Table.Cell>
                            <Table.Cell>{street_unit_lots}</Table.Cell>
                            <Table.Cell colSpan='2'>{formatNumber((street_unit_lots / street_total_lots) * 100)}%</Table.Cell>
                            {/* <Table.Cell>{formatNumber((n_unit_total/street_total_res_lots) * 100)}%</Table.Cell> 
                        </Table.Row>

                    </Table.Body>
                </>
            ) : null}

            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan='4'></Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell>Street Density</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2'>{selectedLot.neighborhood} Avg</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell textAlign='left'>
                        # of Res Units
                    </Table.Cell>
                    <Table.Cell>{selectedLot.streetTotals && selectedLot.streetTotals.length > 0 ? selectedLot.streetTotals[0].totalResUnits : '-'}</Table.Cell>
                    <Table.Cell colSpan='2'>{formatNumber(neighborhoodAverageResUnits)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell textAlign='left'>
                        Res Density Factor
                    </Table.Cell>
                    <Table.Cell>{selectedLot.streetTotals && selectedLot.streetTotals.length > 0 ? formatNumber(selectedLot.streetTotals[0].density) : '-'}</Table.Cell>
                    <Table.Cell colSpan='2'>{formatNumber(neighborhoodDensity)}</Table.Cell>
                </Table.Row>
                {/* <Table.Row>
    <Table.Cell textAlign='left'>
        Average Residential Property Sq Ft
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
</Table.Row> 
                <Table.Row>
                    <Table.Cell textAlign='left'>
                        Avg 1-4 Unit Sq Ft
                    </Table.Cell>
                    <Table.Cell>{formatNumber(getTotalBldgAreaAverage(selectedLot.streetAreaAverages))}</Table.Cell>
                    <Table.Cell colSpan='2'>{formatNumber(getTotalBldgAreaAverage(selectedLot.neighborhoodAreaAverages))}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell textAlign='left'>
                        Avg {actualUnitCode} Unit Sq Ft
                    </Table.Cell>
                    <Table.Cell>{formatNumber(getTotalBldgAreaAverage(selectedLot.streetAreaAverages, actualUnitCode))}</Table.Cell>
                    <Table.Cell colSpan='2'>{formatNumber(getTotalBldgAreaAverage(selectedLot.neighborhoodAreaAverages, actualUnitCode))}</Table.Cell>
                </Table.Row>
            </Table.Body>

            {
                selectedLot.streetSaleTotals ? (
                    <>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='4'></Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>36 Mo Block Turnover</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>
                                <Table.HeaderCell>Percentage</Table.HeaderCell>
                                <Table.HeaderCell>{selectedLot.neighborhood} Avg</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {/* <Table.Row>
    <Table.Cell textAlign='left'>
        36 Month Residential Sales
    </Table.Cell>
    <Table.Cell>{selectedLot.turnovers[0].totalSales}</Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
</Table.Row> 
                            <Table.Row>
                                <Table.Cell textAlign='left'>
                                    1-4 Unit Sales
                                </Table.Cell>
                                <Table.Cell>{getTotalStreetTurnoverSales(selectedLot.streetSaleTotals, selectedLot.streets[0].streetId)}</Table.Cell>
                                <Table.Cell>{formatNumber(streetTotalTurnover)}%</Table.Cell>
                                <Table.Cell>{formatNumber(neighborhoodTotalTurnover)}%</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell textAlign='left'>
                                    {actualUnitCode} Unit Sales
                                </Table.Cell>
                                <Table.Cell>{getTotalStreetTurnoverSales(selectedLot.streetSaleTotals, selectedLot.streets[0].streetId, actualUnitCode)}</Table.Cell>
                                <Table.Cell>{formatNumber(streetUnitTurnover)}%</Table.Cell>
                                <Table.Cell>{formatNumber(neighborhoodUnitTurnover)}%</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </>
                ) : null
            }
            {/* 
<Table.Header>
<Table.Row>
    <Table.HeaderCell colSpan='4'></Table.HeaderCell>
</Table.Row>
<Table.Row>
    <Table.HeaderCell>12 Mo Block Pricing</Table.HeaderCell>
    <Table.HeaderCell>Total</Table.HeaderCell>
    <Table.HeaderCell colSpan='2'>{selectedLot.neighborhood} Avg</Table.HeaderCell>
</Table.Row>
</Table.Header>

<Table.Body>
 <Table.Row>
    <Table.Cell textAlign='left'>
        12 Month Average Residential PSF
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
</Table.Row> 
<Table.Row>
    <Table.Cell textAlign='left'>
        Avg 1-4 Unit PSF
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell colSpan='2'></Table.Cell>
</Table.Row>
<Table.Row>
    <Table.Cell textAlign='left'>
        Avg {actualUnitCode} Unit PSF
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell colSpan='2'></Table.Cell>
</Table.Row>
</Table.Body> 

        </Table>

    </>
)}
*/

export const CompsExcludedSidebar = ({ animation, direction, width, compsLoading, compsStats, compAnalytics, aiBucket, finalCombinedBuckets, composyteBuckets, simpleBuckets, erSimpleBuckets, topZBuckets, topComps, tdsChart }) => {
    const [visible, setVisible] = useState(true)

    return (<Sidebar
        className='comps-excluded-side-panel-container'
        as={Segment}
        animation={animation}
        direction={direction}
        visible={visible}
        width={width}
    >
        <Grid style={{ height: '100%' }}>
            <Label className='panel-closer left comps-panel-closer' basic icon={`long arrow alternate ${visible ? 'right' : 'left'}`} floating onClick={() => { setVisible(visible => !visible) }}></Label>
            <Grid.Row columns={1} style={{ height: '100%' }}>
                <Grid.Column style={{ height: '100%' }}>
                    {(composyteBuckets) && (
                        // <CompsExtraCalcPanel calc={compsCalc} loading={compsLoading} tdsChart={tdsChart} bestBuckets={bestBuckets} />
                        <CompsExtraCalcPanel 
                        compsData={compsStats?.valuationData}
                         aiBucket={aiBucket}
                         finalCombinedBuckets={finalCombinedBuckets}
                          composyteBuckets={composyteBuckets}
                           simpleBuckets={simpleBuckets}
                            erSimpleBuckets={erSimpleBuckets} 
                            topZBuckets={topZBuckets}
                            composyteScatterData={compsStats?.scatterData}
                             topComps={topComps}
                              tdsChart={tdsChart}
                              analytics={compAnalytics}
                              loading={compsLoading} />
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Sidebar>)
}